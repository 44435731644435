<template>
  <div>
    <Services />
  </div>
</template>

<script>
import Services from '@/components/Services.vue'


export default {
  name: 'influence',
  components: {
    Services
  }
}
</script>