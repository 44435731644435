<template>
  <div class="flex flex-col flex-wrap md:flex-row bg-white overflow-hidden">
    <div class="highlight w-full">
      <div class="relative z-10 p-8 sm:pb-16 md:max-w-4xl md:w-full mx-auto">
          <p>Curious Bird works with nonprofits, universities, and values-driven companies to create games, learning, and other interactive experiences focused on meaningful change.</p><p>You can see examples of our work in our <router-link :to="{ name: 'projects' }">projects portfolio</router-link>.</p>
          <h2 class="text-2xl mt-8">Our expertise includes:</h2>
          <div class="flex flex-col flex-wrap md:flex-row mt-3">
            <div class="order-1 w-full md:w-1/3">
              <ul>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Simulations
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Games
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      eLearning
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Workshops
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="order-2 w-full md:w-1/3">
              <ul>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Needs assessment
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Learning strategy
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Learning pathway design
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="order-3 w-full md:w-1/3">
              <ul>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      HTML5 development
                    </div>
                  </div>
                </li>
                <li class="mt-2">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <img src="../assets/images/leaf.png" class="w-6">
                    </div>
                    <div class="ml-4">
                      Unity / C# development
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-8 w-full ">
            <img class="object-cover left-0 md:w-2/3 mx-auto w-full" src="../assets/images/swallows.png" alt="">
          </div>
          <h1 class="text-4xl mt-8">Exploration Package</h1>
          <p>Are you interested in creating a game, simulation, course, or other interactive experience but aren't sure where to begin? Consider hiring us for a shorter exploration package.</p> 
          <p>This includes:</p>
          <ul>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Two to three digital ideation sessions</b> to discuss your objectives and how to achieve them using a game, simulation, course, or other interactive experience.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Topic research</b>, often including stakeholder and target audience interviews.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Mockups</b> to test and discuss ideas.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>A written summary of ideas and recommendations</b>, delivered after the final ideation session. This summary can be used to kickstart a roadmap towards full development or can be included in funding applications.
                </div>
              </div>
            </li>
          </ul>
          <div class="border-4 rounded-lg border-gray-400/50 p-3 mt-5">
            <p class="mt-1"><b>Cost for an exploration package:</b> 1,600 to 4,800 EUR depending on the number of sessions and scope of research.</p><p>Contact us at <a href="mailto:chirp@curiousbird.se">chirp@curiousbird.se</a> for more information or a customized quote.</p>
          </div>
          <h1 class="text-4xl mt-8">Exploration Package + Prototypes</h1>
          <p>This package includes everything in the base exploration package plus one or more digital prototypes. Prototyping is a great way to test and refine ideas early on, saving time and resources in the long run. They can also be used as proof of concepts when searching for grants or other additional funding.</p> 
          <p>This includes:</p>
          <ul>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Three to five digital ideation sessions</b> to discuss your objectives and how to achieve them using a game, simulation, course, or other interactive experience.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Topic research</b>, often including stakeholder and target audience interviews.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>Mockups and one or more digital prototypes</b> to test and discuss ideas.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>A written summary of ideas and recommendations</b>, delivered after the final ideation session. This summary can be used to kickstart a roadmap towards full development or can be included in funding applications.
                </div>
              </div>
            </li>
          </ul>
          <div class="border-4 rounded-lg border-gray-400/50 p-3 mt-5">
            <p class="mt-1"><b>Cost for an exploration package:</b> 4,800 to 12,000 EUR depending on the number of sessions, scope of research, and scale of prototyping.</p><p>Contact us at <a href="mailto:chirp@curiousbird.se">chirp@curiousbird.se</a> for more information or a customized quote.</p>
          </div>
          <h1 class="text-4xl mt-10">Full Services</h1>
          <p>We also offer a comprehensive suite of services to guide the journey from initial concept to polished product.</p> 
          <h2 class="text-3xl mt-10">Needs Analysis and Ideation</h2>
          <p>As part of a <b>needs analysis</b>, we'll work with you to uncover the key objectives, challenges, and opportunities for your project through discussions, interviews, and research.</p>
          <p>Then we'll transform these objectives into <b>ideas for interactivity</b>, through mock ups, wireframes, and clickable prototypes. These allow us to explore different concepts, mechanics, and features early in the process, saving time and resources in the long run.</p>
          <p>During this phase, we'll iterate quickly based on testing and feedback.</p>
          <h2 class="text-3xl mt-10">Design and Development</h2>
          <p>This phase will vary depending on the needs of the project.</p>
          <p>Often we'll start with a <b>design document</b> that outlines the overall structure, sequence, and style of the game or experience. However, we believe strongly in iteration over time, so this document should be seen as a high level guide, not a detailed rulebook.</p>
          <p>Then we'll start development, generally in either custom HTML5 or Unity/C#, depending on the target audience, planned delivery channels, and other project needs.</p>
          <p>Common development milestones are:</p>
          <ul>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>A functional prototype</b> to test the core interactivity. 
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>An alpha version</b> of the experience as a whole, with placeholder art and audio.
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>A beta version</b>, with final media. 
                </div>
              </div>
            </li>
            <li class="mt-5">
              <div class="flex">
                <div class="flex-shrink-0">
                  <img src="../assets/images/leaf.png" class="w-6">
                </div>
                <div class="ml-4">
                  <b>A gold version</b>, ready for release.
                </div>
              </div>
            </li>
          </ul>
          <p>You will have the opportunity to give feedback after each phase. We also recommend testing with end users and getting their feedback at multiple stages, preferably early on.</p>
          <div class="border-4 rounded-lg border-gray-400/50 p-3 mt-5">
            <p class="mt-1"><b>Cost for a full project:</b> The budget of our projects varies significantly depending on the scope of the final product and our roles in its creation.</p> 
            <ul>
              <li class="mt-5">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <img src="../assets/images/leaf.png" class="w-6">
                  </div>
                  <div class="ml-4">
                    <b>For simpler research tools or digital interactions</b>, our typical range is 8,000 to 30,000 EUR.
                  </div>
                </div>
              </li>
              <li class="mt-5">
                <div class="flex">
                  <div class="flex-shrink-0">
                    <img src="../assets/images/leaf.png" class="w-6">
                  </div>
                  <div class="ml-4">
                    <b>For a complete game or course</b>, our typical range is 30,000 to 100,000 EUR.
                  </div>
                </div>
              </li>
            </ul>
            <p>Contact us at <a href="mailto:chirp@curiousbird.se">chirp@curiousbird.se</a> for more information or a customized quote.</p>
          </div>
        </div>
      </div>

</div>
</template>

<script>
export default {
  name: 'Services',

}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

button, .button {
  background-color: #95C11F;
  cursor: pointer;
}

</style>
